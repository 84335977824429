import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="flex flex-1 items-center justify-center text-white">
      <div className="border border-green-600 max-w-sm px-6 py-4">
        <h2 className="leading-none m-0 py-2 text-4xl">Page not found</h2>
        <p className="pb-2">They page you requested cannot be found.</p>
        <p className="pb-4 text-center">
          <Link
            className="bg-black hover:bg-green-600 border border-green-600 border-2 border-white font-display leading-none hover:no-underline px-6 py-2 text-xs text-white hover:text-black uppercase"
            to="/"
          >
            Return to home
          </Link>
        </p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
